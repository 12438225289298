<template>
  <div id="supplier-users">
    <v-container
      fluid
      class="pl-0 pr-0"
    >
      <Header />

      <Alert
        :showAlert="isErrorMessage"
        v-if="isErrorMessage"
        type="error"
        class="mt-2"
        allowDismiss
        :text="errorMessage"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
    <v-container
      fluid
      class="d-flex flex-column px-2"
    >
      <AdsButton
        width="220"
        class="mb-2"
        :primary="true"
        buttonText="Add Supplier User"
        icon="mdi-plus"
        @click="createNewSupplierUser"
        :disabled="isReadOnlyUser"
      />
      <AdsDataTable
        :headers="headers"
        :items="items"
        :footer-props="{
          'items-per-page-options': [20, 30, 50, -1],
        }"
        :items-per-page="20"
        search-label="Find supplier Users"
        sort-desc
        @click:row="openSupplierUser"
      >
        <template v-slot:item.status="{ item }">
          <v-chip
            color="green"
            textColor="white"
            v-if="item.status === 'Active'"
          >
            {{ item.status }}
          </v-chip>
          <v-chip
            color="orange"
            v-if="item.status === 'Deprecated'"
          >
            {{ item.status }}
          </v-chip>
        </template>
      </AdsDataTable>
    </v-container>
  </div>
</template>
<script>
import { AdsDataTable, AdsButton, Alert } from '@nswdoe/doe-ui-core'
import Header from '@/views/SupplierUsers/Header/Header'

export default {
  name: 'SupplierUsersListing',
  title: 'Supplier Users - SAIS Operation UI Portal',
  components: {
    AdsDataTable,
    AdsButton,
    Header,
    Alert,
  },
  data() {
    return {
      headers: [
        { text: 'Supplier', value: 'supplierName' },
        { text: 'Email', value: 'emailAddress' },
        { text: 'Given Name', value: 'firstName' },
        { text: 'Surname', value: 'lastName' },
        { text: 'Status', value: 'status' },
        { text: 'Mobile No', value: 'mobileNumber' },
      ],
      items: [],
    }
  },
  created() {
    this.$store.dispatch('moduleSupplierUsers/fetchSupplierUsers').finally(() => {
      this.items = this.$store.state.moduleSupplierUsers.supplierUsers
    })
  },

  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
    errorMessage() {
      return this.$store.state.moduleSuppliers.errorMessage
    },
    isErrorMessage() {
      const errorMessage = this.$store.state.moduleSuppliers.errorMessage
      return !!errorMessage && errorMessage.length > 0
    },
  },

  methods: {
    openSupplierUser(item) {
      this.$router.push(
        `/serviceregistry/supplierusers/view/${item.id}?supplierId=${item.supplierId}`
      )
    },
    createNewSupplierUser() {
      this.$router.push('/serviceregistry/supplierusers/add')
    },
  },
}
</script>
